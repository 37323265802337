import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Grid, TextField, Card, TextareaAutosize } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import env from 'config';

const Instruction = () => {
    // State variables to manage form inputs

    const [pluginInfo, setPluginInfo] = useState('');
    const [data, setdata] = useState([]);

    const BASE_URL = `${env.API_URL}/v1`;

    useEffect(() => {
        axios.get(BASE_URL + '/super_admin_settings/')
            .then(response => {
                const data = response.data.data;
                console.log("resp", response?.data?.data)
                setdata(response?.data?.data);
                setPluginInfo(response?.data?.data?.contact_prompt)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            contact_prompt: pluginInfo,
            _id:data._id
        };

        axios.put(BASE_URL + '/super_admin_settings/', payload)
            .then(response => {
                console.log('Data updated successfully:', response.data);
            })
            .catch(error => {
                console.error('Error updating data:', error);
            });
    };

    return (
        <>
            <Grid item xs={12} py={6}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} px={3}>
                       
                        
                        <Grid item xs={12} sm={12}>
                            <textarea
                               style={{width:"100%",padding:"10px"}}
                                rows={10}
                                fullWidth
                                label="plugins"
                                variant="outlined"
                                value={pluginInfo}
                                onChange={(e) => setPluginInfo(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ color: "white" }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Footer />
        </>
    );
};

export default Instruction;
