import React, { useEffect, useState } from 'react';
import { useAppServices } from 'hook/services';
import Grid from "@mui/material/Grid";
import { Switch, Button, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import axios from 'axios';
import env from 'config'

const DefaultPlugins = () => {
  const [supperadminsetting, setSupperadminsetting] = useState({})
  const [pluginsdata, setPluginsData] = useState([])
  const [activePlugins, setActivePlugins] = useState([]);
  const [loading, setLoading] = useState(false);

  const BASE_URL = `${env.API_URL}/v1`
  console.log("supperadminsetting", supperadminsetting)
  const Service = useAppServices()
  const loadPluginData = async () => {
    // setWaiting(true);
    const { response } = await Service.super_admin_settings.Getplugin();
    // Service.super_admin_settings.get()
    console.log("dds", response)
    if (response) {
      setPluginsData(response?.data?.data);
    }
  };
  // Array to store active plugin names
  // console.log("plugins", activePlugins)
  // Handle toggle change for both plugins
  useEffect(() => {
    if (supperadminsetting?.default_plugins) {
      const activePluginIds = supperadminsetting?.default_plugins?.map(plugin => plugin.id);
      setActivePlugins(pluginsdata?.filter(plugin => activePluginIds.includes(plugin.id)));
    }
  }, [supperadminsetting, pluginsdata]);
  const handlePluginsChange = (event) => {
    const plugin = pluginsdata.find(plugin => plugin.name === event.target.name);

    // Toggle the plugin object in the active plugins array
    setActivePlugins((prevState) => {
      const pluginInState = prevState.find(activePlugin => activePlugin.id === plugin.id);

      if (pluginInState) {
        // If the plugin is already active, remove it from the active array
        return prevState?.filter(activePlugin => activePlugin.id !== plugin.id);
      } else {
        // Otherwise, add the plugin (id and name) to the active array
        return [...prevState, { id: plugin.id, name: plugin.name }];
      }
    });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("Submitting active plugins:", activePlugins);
    setLoading(true)
    const payload = {
      _id: supperadminsetting._id,
      activePlugins: activePlugins
    }
    try {
      const response = await axios.post(BASE_URL + '/super_admin_settings/default_plugins', payload);

      console.log('Response:', response);
      setLoading(false)
      //   onLoad()
    } catch (error) {
      // Handle error
      console.error('Error:', error);
      //   setErrormsg(error.response?.data?.message || 'An error occurred');
    } finally {
      // This will run no matter what (whether an error occurred or not)
      setLoading(false);
    }
  };
  useEffect(async () => {
    axios.get(BASE_URL + '/super_admin_settings/').then(async function (response) {
      setSupperadminsetting(response.data.data)
    })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const onLoad = () => {
    loadPluginData()
  }
  useEffect(() => {
    onLoad()
  }, [])
  return (
    <>
      <h2 className='text-black text-left font-10' style={{ fontWeight: "700", marginBottom: '20px', marginTop: '20px' }}> Plugins </h2>
      {pluginsdata?.map(plugin => (
        
        <Grid item xs={12} style={{ marginBottom: '20px' }} key={plugin.id}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
           
            <label className="text-black" style={{ marginRight: '10px', fontSize: "16px" }}>
              {plugin.name.replace(/_/g, ' ').toUpperCase()}
            </label>
           
            <Switch
              checked={activePlugins.some(activePlugin => activePlugin.id === plugin.id)}
              onChange={handlePluginsChange}
              name={plugin.name}
              inputProps={{ 'aria-label': `Enable ${plugin.name.replace(/_/g, ' ')}` }}
            />
          </div>
        </Grid>

      ))}
      <div style={{ textAlign: "right" }}>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          onClick={submitHandler}
        //   loading={processing}
        //   disabled={processing}
        >
          {loading ? "Loading..." : "Submit"}
        </MDButton>
      </div>
    </>
  )
}

export default DefaultPlugins

