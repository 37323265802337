import React, { useEffect, useState } from "react";
import { Alert, Button, Grid, Modal, TextField, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "examples/Loader";
import { useAppServices } from "hook/services";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Select from 'react-select'

const Default = () => {
    const [data, setData] = useState([]); // Stores all records
    const [currentRecord, setCurrentRecord] = useState(null); // Used for editing
    const [modalOpen, setModalOpen] = useState(false); // Controls modal visibility
    const [form, setForm] = useState({ name: "", description: "" }); // Form state
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState("");
    const [agencies, setagencies] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [editableid, setEditableid] = useState("");

    const AppService = useAppServices();
    const Getagencies = async () => {
        const { response } = await AppService.agencies.Get();
        if (response.success) {
            console.log(response.data);
            const agenciewsss = response.data.map((item) => ({
                label: item.name || item.domain || item.location_id || item.ghl.location_id || "Default Label", // Fallback to a default label if none are available
                value: item._id,
            }));
            setagencies(agenciewsss);
        }
    }
    const GetNotification = async () => {
        setLoader(true);
        const { response } = await AppService.notification.Get();
        if (response.success) {
            setData(response.data);
            console.log(response.data)
            setLoader(false);
        }
    }
    useEffect(() => {
        // Simulate fetch data on component load
        GetNotification()
        Getagencies();
    }, []);

    const handleOpenModal = (record = null) => {
        if (record?._id) {
            setEditableid(record._id)
            const notifyto = record?.notifyto?.map((item) => item = { label: item.agency_name, value: item.agency_id })
        } else {
            setSelectedOptions([])

        }
        setCurrentRecord(record);
        setForm(record || { name: "", description: "" });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setForm({ name: "", description: "" });
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = async (e) => {
        if (!form.name || !form.description) {
            setAlert("Please fill out all fields.");
            return;
        }
        const notifyto = selectedOptions.map((item) => item = { agency_id: item.value, agency_name: item.label, status: "active" })
        setAlert("");
        let payload;
        if (editableid) {
            payload = {
                _id: editableid,
                name: form.name,
                description: form.description,
                notifyto: notifyto,
            };
        } else {
            payload = {
                name: form.name,
                description: form.description,
                notifyto: notifyto,
            };
        }

        console.log(payload, "formformform")
        const { response } = await AppService.notification.create({ payload });
        if (response.success) {
            // Edit existing record
            GetNotification();
        }
        handleCloseModal();
    };

    const handleDelete = async (_id) => {
        const { response } = await AppService.notification.Delete({
            query: `_id=${_id}`,
        });
        if (response.success) {
            GetNotification();
        }
        handleCloseModal();
    };
    const handleChangeSelect = (selected) => {
        setSelectedOptions(selected || []); // Update state with selected options
        console.log("Selected Options:", selected);
    };
    return (
        <>
            {/* {loader ? (
                <Loader />
            ) : ( */}
            <DashboardLayout>
                <DashboardNavbar type="location_prospective" handleChange={""} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button variant="contained" color="white" onClick={() => handleOpenModal()}>
                            Add New Record
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {alert && <Alert severity="error">{alert}</Alert>}
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Included</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data && data.map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell>{row._id}</TableCell>
                                            <TableCell>{row.name}</TableCell>

                                            <TableCell>{row.description}</TableCell>
                                            <TableCell>
                                                {row.notifyto.map((item, index) => (
                                                    <span key={index}>
                                                        {item?.agency_name}
                                                        {index < row.notifyto.length - 1 && ', '}
                                                    </span>
                                                ))}
                                            </TableCell>

                                            <TableCell>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleOpenModal(row)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => handleDelete(row._id)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DashboardLayout>
            {/*  )} */}

            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <h2>{currentRecord ? "Edit Record" : "Add New Record"}</h2>

                    <TextField
                        fullWidth
                        margin="normal"
                        label="Name"
                        name="name"
                        value={form.name}
                        onChange={handleFormChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Description"
                        name="description"
                        value={form.description}
                        onChange={handleFormChange}
                    />
                    <MDBox sx={{ fontSize: '15px' }}>
                        <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                            Agencies
                        </MDTypography>
                        <Select
                            isMulti={true}
                            closeMenuOnSelect={true}
                            defaultValue={selectedOptions}
                            onChange={handleChangeSelect}
                            options={agencies}
                        />

                    </MDBox>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        sx={{ mt: 2 }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCloseModal}
                        sx={{ mt: 2, ml: 2 }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default Default;
