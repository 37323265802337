import React, { useEffect, useState } from 'react';
import { useAppServices } from 'hook/services';
import Grid from "@mui/material/Grid";
import { Switch, Button, TextField, Alert, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import axios from 'axios';
import env from 'config'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';

const DefaultPlugins = () => {
  const [pluginsdata, setPluginsData] = useState([])
  const [activePlugins, setActivePlugins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getAllAgency, setGetAllAgency] = useState();
  const [errormsg, setErrorMsg] = useState('');
  const [response, setresponse] = useState('')
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAgencies, setFilteredAgencies] = useState([]);

  useEffect(() => {
    if (getAllAgency) {
      const filtered = getAllAgency.filter((agency) => {
        const name = agency.name || "";
        const domain = agency.domain || "";
        const location_id = agency?.ghl?.location_id || agency?.location_id;
        return (
          name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          domain.toLowerCase().includes(searchQuery.toLowerCase()) ||
          location_id.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredAgencies(filtered);
    }
  }, [searchQuery, getAllAgency]);



  const BASE_URL = `${env.API_URL}/v1`

  useEffect(async () => {
    try {
      const response = await axios.get(BASE_URL + '/agency/all-agency');

      setGetAllAgency(response?.data?.data)

    } catch (error) {
      console.log("errorerrorerror")

    }
  }, [])


  const Service = useAppServices()
  const loadPluginData = async () => {
    const { response } = await Service.super_admin_settings.Getplugin();

    if (response) {
      setPluginsData(response?.data?.data);

    }
  };

  useEffect(() => {
    if (getAllAgency && pluginsdata.length) {
      const newActivePlugins = {};
      getAllAgency.forEach(agency => {
        if (agency.default_plugins && agency.default_plugins.length > 0) {
          const activePluginIds = agency.default_plugins.map(plugin => plugin.id);
          newActivePlugins[agency._id] = pluginsdata.filter(plugin => activePluginIds.includes(plugin.id));
        }
      });
      setActivePlugins(newActivePlugins);
    }
  }, [getAllAgency, pluginsdata]);
  const handlePluginsChange = (agencyId, plugin) => {
    setActivePlugins(prevState => {
      const agencyPlugins = prevState[agencyId] || []; // Get current plugins for the agency
      const isActive = agencyPlugins.some(activePlugin => activePlugin.id === plugin.id);

      // Toggle the plugin's state
      const updatedPlugins = isActive
        ? agencyPlugins.filter(activePlugin => activePlugin.id !== plugin.id) // Remove if active
        : [...agencyPlugins, { id: plugin.id, name: plugin.name }]; // Add if inactive

      // Return a new state object
      return { ...prevState, [agencyId]: updatedPlugins };
    });
  };



  const AgencySubmitHandler = async (agencyId) => {
    setLoading((prev) => ({ ...prev, [agencyId]: true }));
    const payload = {
      _id: agencyId,
      activePlugins: activePlugins[agencyId] || []
    }

    try {
      const response = await axios.put(BASE_URL + '/agency/update-plugin', payload);
      setresponse(response.data.message)

      setLoading((prev) => ({ ...prev, [agencyId]: false }))
    } catch (error) {
      console.error('Error:', error);
      setErrorMsg(error.response.data.message)

    } finally {
      setLoading((prev) => ({ ...prev, [agencyId]: false }));
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setresponse("")
    }, 4000)
  }, [AgencySubmitHandler])

  const onLoad = () => {
    loadPluginData()
  }
  useEffect(() => {
    onLoad()
  }, [])
  const formatString = (str) => {
    // Replace underscores with spaces
    str = str.replace(/_/g, ' ');

    // Add space before each capital letter, ensuring no double spaces
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Trim and return the final result
    return str.trim();
  };
  return (
    <>
      <div style={{ textAlign: "right", padding: "30px 0px 20px 0px" }}>

        <TextField
          label="Search by Name or Domain"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: "20px", width: "300px" }}
        />

        {errormsg != '' ? (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">{errormsg}</Alert>
          </Stack>
        ) : (
          <></>
        )}
        {response != '' ? (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="success">{response}</Alert>
          </Stack>
        ) : (
          <></>
        )}
        {filteredAgencies?.map((data, index) => (
          <Accordion key={index}>

            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>
                {data?.name?.trim() || data?.domain?.trim() || 'No Domain or Name'} - {data?.ghl?.location_id ? data?.ghl?.location_id : data?.location_id}
              </Typography>

            </AccordionSummary>
            <AccordionDetails >
              {pluginsdata?.map(plugin => (
                <div style={{ marginBottom: '20px', display: "block" }} key={plugin?.id}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label className="text-black" style={{ marginRight: '10px', fontSize: "16px" }}>
                      {formatString(plugin.name).toUpperCase()}
                    </label>
                    <Switch
                      checked={(activePlugins[data?._id] || []).some(activePlugin => activePlugin.id === plugin?.id)}
                      onChange={() => handlePluginsChange(data?._id, plugin)}
                      name={plugin?.name}
                      inputProps={{ 'aria-label': `Enable ${plugin.name.replace(/_/g, ' ')}` }}
                    />

                  </div>
                </div>

              ))}

              <MDButton
                variant="gradient"
                color="info"
                onClick={() => AgencySubmitHandler(data?._id)}
              >
                {loading[data?._id] ? "Loading..." : "Submit"}
              </MDButton>
            </AccordionDetails>
          </Accordion>
        ))}

      </div>
    </>
  )
}

export default DefaultPlugins

