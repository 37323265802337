import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Grid, TextField, Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import env from 'config';
import Instruction from './components/Instructions/Instruction';
import Header from './components/Header';
import DefaultPlugins from './components/Plugin/DefaultPlugins';

const AgencySetting = () => {
 

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <Grid container mt={10}>
          <Grid item xs={12}>
            <Header elements={[<Instruction/>]} />
          </Grid>
        </Grid>
      </DashboardLayout>
  );
};

export default AgencySetting;
